import styled from "styled-components/macro";
import { appColors } from "../../colours/colours";
import BannerDoumbell from "../../../../assets/images/banner-doumbells.webp";
import { ClickEditError, ClickEditInput } from "../forms/FormComponents";
export const SideBarContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: block;
`;

export const SideBarContents = styled.div`
  width: 100%;
  background-color: #fff;
  display: block;
  position: relative;
`;

export const SideBarLogoContainer = styled.div`
  position: absolute;
  top: -75px;
  left: 20px;
`;

export const SideBarBannerContainer = styled.div`
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${BannerDoumbell});
`;

export const SideBarDetailsContainer = styled.div`
  padding: 25px 20px 0px 20px;
`;

export const SideBarDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 0px 10px 0px;
`;
export const SideBarDetailIcon = styled.i`
  font-size: 18px;
  color: ${appColors.defaultFont};
  margin-right: 10px;
`;
export const SideBarGymName = styled(ClickEditInput)`
  font-size: 20px;
  color: ${appColors.defaultFont};
  font-weight: bold;
`;
export const SideBarTextEdit = styled(ClickEditInput)`
  font-size: 18px;
  color: ${appColors.defaultFont};
`;

export const SideBarTextArea = styled.textarea`
  width: 100%;
  font-size: 18px;
  color: ${appColors.defaultFont};
  border: none;
  margin: 0;
  ::-webkit-scrollbar {
    display: none;
  }

  &:focus {
    border: none;
  }

  &:focus-visible {
    border: none;
    outline: none;
  }
`;

export const LogoutButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: ${appColors.defaultBackground};
  height: 30px;
  width: 30px;
  border: none;
  color: white;
  padding: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 0 10px #ddd;
`;

export const LogoutButtonIcon = styled.i`
  font-size: 22px;
  line-height: 22px;
  color: ${appColors.defaultFont};
  padding: 0;
  margin: 0;
`;

export const SideBarError = styled(ClickEditError)`
  padding: 10px 0px 0px 0px;
`;

export const OffsetCloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: ${appColors.defaultBackground};
  height: 30px;
  width: 30px;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0 0 10px #ddd;
`;

export const OffsetCloseIcon = styled.i`
  font-size: 22px;
  line-height: 22px;
  color: ${appColors.defaultFont};
  padding: 0;
  margin: 0;
  transition: 5s linear;
`;
