import React, { useEffect, useRef, useState } from "react";
import ClientsContainer from "./ClientsContainer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/hooks";
import {
  clearMessages,
  clientsDataRequest,
  clientsDeleteRequest,
  setCurrentReducer,
  setFilterReducer,
  setSelectItemsReducer,
  setFilterShadow,
  clearSelectItems,
  clientsAddRequest,
  clear,
  setClientsUpdateModalVisibleReducer,
  setSearchReducer,
  selectListCreateReducer,
} from "../../../redux/slices/ClientsSlice";
import { useNavigate } from "react-router-dom";
import { setGymsUpdateModalVisibleReducer } from "../../../redux/slices/GymSlice";
const filterItems: Array<object> = [
  {
    label: "All",
    id: "name",
  },
  {
    label: "Inactive Only",
    id: "inactive_clients",
  },
  {
    label: "Active Only",
    id: "active_clients",
  },
];

const Clients = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clients = useAppSelector((state) => state.clients);
  const [newPackageModalShow, setNewPackageModalShow] = useState(false);
  const [actionItemModalShow, setActionItemModalShow] = useState<boolean>(false);
  const [messageModalShow, setMessageModalShow] = useState(false);
  const [actionSelect, setActionSelect] = useState(false);
  const {
    clientsData,
    isLoading,
    error,
    finalLoad,
    limit,
    apiStatus,
    successMessage,
    currentPosition,
    filter,
    selectItems,
    filterShadow,
    clientsUpdateModalVisible,
    search,
  } = clients;
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (!firstUpdate.current && !filterShadow) {
      getClientsData();
    }
  }, [currentPosition]);

  useEffect(() => {
    if (!firstUpdate.current) {
      dispatch(setCurrentReducer(0));
      dispatch(clear());
      dispatch(setFilterShadow());
      dispatch(clearSelectItems());
    }
  }, [filter, search]);

  useEffect(() => {
    filterShadow && getClientsData();
  }, [filterShadow]);

  useEffect(() => {
    if (firstUpdate.current) {
      getClientsData();
      firstUpdate.current = false;
    }
  }, []);

  useEffect(()=>{
    selectItems.length === 1 && actionSelect &&  onToggleShowActionItems(true);
  },[selectItems])

  const setCurrent = (param: number) => {
    dispatch(setCurrentReducer(param));
  };
  const setFilter = (param: string) => {
    dispatch(setFilterReducer(param));
  };
  const setselectItems = (param: Array<object>) => {
    dispatch(setSelectItemsReducer(param));
  };

  const getClientsData = () => {
    const urlParams = `/${currentPosition}/${limit}/${filter}/'${search}'`;
    dispatch(clientsDataRequest(urlParams));
  };

  const selectAllHandle = (list: Array<object>) => {
    setselectItems(list);
  };

  const deleteClientsGroup = () => {
    if (selectItems.length > 0) {
      const params: object = {
        ids: selectItems.toString(),
      };
      dispatch(clientsDeleteRequest(params));
    }
  };

  const clearAllMessages = () => {
    dispatch(clearMessages());
  };

  const addClients = (params: object) => {
    dispatch(clientsAddRequest(params));
  };

  const navigateToAdd = () => {
    navigate("add");
  };

  // Update section

  const clearUpdate = () => {
    dispatch(setClientsUpdateModalVisibleReducer(false));
    setNewPackageModalShow(false);
    window.location.reload();
  };

  const onClickItem = (item: any) => {
    dispatch(selectListCreateReducer(item));
    !selectItems.includes(item) && setActionSelect(true);
  };

  const onLongPress = (item: any) => {
    dispatch(selectListCreateReducer(item));
  };

  const onUpdateModalClose = () => {
    dispatch(setClientsUpdateModalVisibleReducer(false));
  };

  const onNewPackageModalClose = () => {
    clearUpdate();
  };

  const onNewPackageModalShow = () => {
    onUpdateModalClose();
    setNewPackageModalShow(true);
  };

  // update section end

  // Message section end
  const onMessageModalShow = (item: any) => {
    setMessageModalShow(true);
  };

  const onMessageModalClose = () => {
    setMessageModalShow(false);
  };

  // Message section end

  // search section

  const onSearch = (value: string) => {
    dispatch(setSearchReducer(value));
  };

  // search section end

  const onEditPress = () => {
    dispatch(setClientsUpdateModalVisibleReducer(true));
  };

  const onDeletePress = () => {
    console.log("delete");
  };

  const onRenewPackagePress = () => {
    setNewPackageModalShow(true);
  };

  const onToggleShowActionItems = (action: boolean) => {
    setActionItemModalShow(action);
    if(!action){
      setselectItems([]);
      setActionSelect(false);

    }
  };

  return (
    <ClientsContainer
      clientsData={clientsData}
      isLoading={isLoading}
      error={error}
      filterItems={filterItems}
      filterState={[filter, setFilter]}
      selectState={[selectItems]}
      finalLoad={finalLoad}
      currentState={[currentPosition, setCurrent]}
      limit={limit}
      apiStatus={apiStatus}
      deleteClientsGroup={deleteClientsGroup}
      successMessage={successMessage}
      clearMessages={clearAllMessages}
      selectAllHandle={selectAllHandle}
      addClients={addClients}
      updateModalState={[
        onClickItem,
        onNewPackageModalShow,
        clientsUpdateModalVisible,
        onUpdateModalClose,
        clearUpdate,
        onToggleShowActionItems,
        actionItemModalShow
      ]}
      newPackageModalState={[newPackageModalShow, onNewPackageModalClose]}
      messageModalState={[
        messageModalShow,
        onMessageModalClose,
        onMessageModalShow,
      ]}
      onSearch={onSearch}
      navigateToAdd={navigateToAdd}
      onEditPress={onEditPress}
      onDeletePress={onDeletePress}
      onRenewPackagePress={onRenewPackagePress}
      onLongPress={onLongPress}
    />
  );
};

export default Clients;
