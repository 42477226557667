import React from "react";
import {
  FilterButtonContainer,
  FilterButtonText,
} from "../../styled/filterbar/FilterViews";

interface ButtonTypes {
  name: string;
  icon: string;
  onClick: () => void;
}

const FilterAreaButton = ({ name, onClick, icon }: ButtonTypes) => {
  return (
    <FilterButtonContainer onClick={onClick}>
      <i className={icon} />
      <FilterButtonText>{name}</FilterButtonText>
    </FilterButtonContainer>
  );
};

export default FilterAreaButton;
