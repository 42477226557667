import React from "react";
import DashboardContainer from "./DashboardContainer";
import { useAppDispatch } from "../../redux/hooks/hooks";
import { setSideBarShowReducer } from "../../redux/slices/AppUtilSlice";
import { useNavigate } from "react-router-dom";
export default function Dashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onSHowSideBar = () => {
    dispatch(setSideBarShowReducer(true));
  };

  return (
    <div>
      <DashboardContainer onSHowSideBar={onSHowSideBar} navigate={navigate} />
    </div>
  );
}
