import React from "react";
import { ContainerArea } from "../../../views/styled/layout/CommonViews";
import { FilterMenuBar } from "../../../views/styled/filterbar/FilterViews";
import BackButton from "../../../views/components/goback/BackButton";
import { ContentArea } from "../../../views/styled/forms/FormComponents";
import {
  ButtonGreen,
  ButtonRed,
  SubmitButton,
  SubmitButtonContainer,
  SubmitContainer,
} from "../../../views/styled/forms/FormComponents";
import CustomInputFile from "../../../views/components/form/fileUploader/CustomInputFile";
import InputBox from "../../../views/elements/input/InputBox";
import InputSelect from "../../../views/elements/input/InputSelect";
import PackageSelect from "../../../views/elements/input/PackageSelect";
import ModalView from "../../../views/elements/modals/ModalView";
import MessageToast from "../../../views/elements/toast/MesageToast";
import PaymentSuccess from "../clientsList/components/PaymentSuccess";

interface SeletedImageType {
  value: File | null;
  msg: string;
  valid: boolean;
}

interface InputTypeInt {
  value: number;
  msg: string;
  valid: boolean;
}

interface InputTypeString {
  value: string;
  msg: string;
  valid: boolean;
}

interface SuccessTypes {
  status: boolean;
  data: any;
}

interface AddClientsTypes {
  selectImageState: [
    SeletedImageType,
    string,
    (param: React.ChangeEvent<HTMLInputElement>) => void,
  ];
  packageState: [
    InputTypeInt,
    (param: React.ChangeEvent<HTMLSelectElement>) => void,
  ];
  nameState: [
    InputTypeString,
    (param: React.ChangeEvent<HTMLInputElement>) => void,
  ];
  addressState: [
    InputTypeString,
    (param: React.ChangeEvent<HTMLInputElement>) => void,
  ];
  phoneState: [
    InputTypeString,
    (param: React.ChangeEvent<HTMLInputElement>) => void,
  ];
  genderState: [
    InputTypeString,
    (param: React.ChangeEvent<HTMLSelectElement>) => void,
  ];
  genderOptions: Array<object>;
  dobState: [
    InputTypeString,
    (param: React.ChangeEvent<HTMLInputElement>) => void,
  ];
  emailState: [
    InputTypeString,
    (param: React.ChangeEvent<HTMLInputElement>) => void,
  ];
  heightState: [
    InputTypeInt,
    (param: React.ChangeEvent<HTMLInputElement>) => void,
  ];
  weightState: [
    InputTypeInt,
    (param: React.ChangeEvent<HTMLInputElement>) => void,
  ];
  diseaseState: [
    InputTypeString,
    (param: React.ChangeEvent<HTMLInputElement>) => void,
  ];
  disabilityState: [
    InputTypeString,
    (param: React.ChangeEvent<HTMLInputElement>) => void,
  ];
  subscriptionDateState: [
    InputTypeString,
    (param: React.ChangeEvent<HTMLInputElement>) => void,
  ];
  paymentMethodState: [
    InputTypeString,
    (param: React.ChangeEvent<HTMLInputElement>) => void,
  ];
  onSubmit: () => void;
  formError: boolean;
  clearForm: () => void;
  successState: [string, (param: string) => void];
  paymentSuccessState: [SuccessTypes, () => void];
  errorState: [string, (param: string) => void];
  setIsLoading: (param: boolean) => void;
  clearMessages: () => void;
  closeAddClient: () => void;
}

const AddClientsContainer = ({
  selectImageState,
  packageState,
  nameState,
  addressState,
  phoneState,
  genderState,
  genderOptions,
  dobState,
  emailState,
  heightState,
  weightState,
  diseaseState,
  disabilityState,
  subscriptionDateState,
  paymentMethodState,
  onSubmit,
  formError,
  clearForm,
  successState,
  paymentSuccessState,
  errorState,
  setIsLoading,
  clearMessages,
  closeAddClient,
}: AddClientsTypes) => {
  const [selectedImage, imagethumb, onSelectFile] = selectImageState;
  const [packageID, onPackageIdChange] = packageState;
  const [name, onNameChange] = nameState;
  const [address, onAddressChange] = addressState;
  const [phone, onPhoneChange] = phoneState;
  const [gender, onGenderChange] = genderState;
  const [dob, onDobChange] = dobState;
  const [email, onEmailChange] = emailState;
  const [height, onHeightChange] = heightState;
  const [weight, onWeightChange] = weightState;
  const [disease, onDiseaseChange] = diseaseState;
  const [disability, onDisabilityChange] = disabilityState;
  const [subscriptionDate, onSubscriptionDateChange] = subscriptionDateState;
  const [paymentMethod, onPaymentMethodChange] = paymentMethodState;
  const [successMessage, setSuccessMessage] = successState;
  const [onPaymentSuccess, closePaymentSuccess] = paymentSuccessState;
  const [error, setError] = errorState;
  return (
    <ContainerArea>
      <ModalView
        modalShow={onPaymentSuccess.status}
        onHide={closePaymentSuccess}
        modalTitle={"New Client Added Successfully."}
      >
        <PaymentSuccess
          onPaymentSuccess={onPaymentSuccess}
          dataItem={onPaymentSuccess.data}
          setIsLoading={setIsLoading}
          setSuccessMessage={setSuccessMessage}
          setFormError={setError}
        />
      </ModalView>

      <FilterMenuBar>
        <BackButton />
      </FilterMenuBar>

      <ContentArea>
        <form onSubmit={(e) => e.preventDefault()}>
          <CustomInputFile
            onChange={onSelectFile}
            selectedImage={imagethumb}
            error={selectedImage.msg}
          />

          <PackageSelect
            label="Package"
            msg={packageID.msg}
            onChange={onPackageIdChange}
            value={packageID.value}
          />

          <InputBox
            type="text"
            label="Name"
            msg={name.msg}
            value={name.value}
            placeholder="Enter Name"
            onChange={onNameChange}
            onBlur={onNameChange}
          />

          <InputBox
            type="text"
            label="Address"
            msg={address.msg}
            value={address.value}
            placeholder="Enter Address"
            onChange={onAddressChange}
            onBlur={onAddressChange}
          />

          <InputBox
            type="text"
            label="Phone"
            msg={phone.msg}
            value={phone.value}
            placeholder="Enter Phone"
            onChange={onPhoneChange}
            onBlur={onPhoneChange}
          />

          <InputSelect
            label="Select Gender"
            msg={gender.msg}
            options={genderOptions}
            onChange={onGenderChange}
          />

          <InputBox
            type="date"
            label="Birth Date"
            msg={dob.msg}
            value={dob.value}
            placeholder="Enter Birth Date"
            onChange={onDobChange}
            onBlur={onDobChange}
          />

          <InputBox
            type="email"
            label="Email"
            msg={email.msg}
            value={email.value}
            placeholder="Enter Email"
            onChange={onEmailChange}
            onBlur={onEmailChange}
          />

          <InputBox
            type="number"
            label="Height ( in cm )"
            msg={height.msg}
            value={height.value}
            placeholder="Enter Height"
            onChange={onHeightChange}
            onBlur={onHeightChange}
          />

          <InputBox
            type="number"
            label="Weight ( in kg )"
            msg={weight.msg}
            value={weight.value}
            placeholder="Enter Weight"
            onChange={onWeightChange}
            onBlur={onWeightChange}
          />

          <InputBox
            type="text"
            label="Diseases"
            msg={disease.msg}
            value={disease.value}
            placeholder="Enter Diseases"
            onChange={onDiseaseChange}
            onBlur={onDiseaseChange}
          />

          <InputBox
            type="text"
            label="Disability"
            msg={disability.msg}
            value={disability.value}
            placeholder="Enter Disability"
            onChange={onDisabilityChange}
            onBlur={onDisabilityChange}
          />

          <InputBox
            type="date"
            label="Subscription Date"
            msg={subscriptionDate.msg}
            value={subscriptionDate.value}
            placeholder="Enter Subscription Date"
            onChange={onSubscriptionDateChange}
            onBlur={onSubscriptionDateChange}
          />

          <InputBox
            type="text"
            label="Payment Method"
            msg={paymentMethod.msg}
            value={paymentMethod.value}
            placeholder="Cash"
            onChange={onPaymentMethodChange}
            onBlur={onPaymentMethodChange}
          />

          <SubmitContainer>
            <SubmitButtonContainer>
              <SubmitButton onClick={() => onSubmit()} disabled={formError}>
                Add Client
              </SubmitButton>
            </SubmitButtonContainer>

            <SubmitButtonContainer>
              <ButtonGreen
                onClick={() => {
                  clearForm();
                }}
              >
                Clear
              </ButtonGreen>
            </SubmitButtonContainer>

            <SubmitButtonContainer>
              <ButtonRed onClick={closeAddClient}>Cancel</ButtonRed>
            </SubmitButtonContainer>
          </SubmitContainer>
        </form>

        <MessageToast
          error={error}
          successMessage={successMessage}
          clearMessages={clearMessages}
        />
      </ContentArea>
    </ContainerArea>
  );
};

export default AddClientsContainer;
