const SendToWhatsapp = (mobile: string, msg: string) => {
  const formatPhoneNumber = (number: string) => {
    if (number.substring(0, 3) === "+91") {
      return number.replace("+", "");
    } else if (number.charAt(0) === "0") {
      return number.replace("0", "91");
    } else {
      return "91" + number;
    }
  };

  const url: string | null = `https://wa.me/${formatPhoneNumber(
    mobile,
  )}/?text=${msg}`;
  window.open(url, "_blank")?.focus();
};

export default SendToWhatsapp;
