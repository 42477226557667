import styled from "styled-components/macro";
import { appColors } from "../../colours/colours";
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 10vh 0vw;
`;
export const LoginFormContainer = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
  min-height: 80vh;
  width: 30vw;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
  color: ${appColors.defaultFont};
  @media only screen and (max-width: 320px) {
    width: 80vw;
    min-height: 80vh;
    hr {
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 360px) {
    width: 80vw;
    min-height: 80vh;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 411px) {
    width: 80vw;
    min-height: 80vh;
  }
  @media only screen and (min-width: 768px) {
    width: 80vw;
    min-height: 80vh;
  }
  @media only screen and (min-width: 1024px) {
    width: 70vw;
    min-height: 80vh;
  }
  @media only screen and (min-width: 1280px) {
    width: 30vw;
    min-height: 80vh;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WelcomeText = styled.h2`
  padding: 0rem 0 0 0;
`;

export const HorizontalRule = styled.hr`
  width: 90%;
  height: 0.2rem;
  border-radius: 0.8rem;
  border: none;
  background: linear-gradient(to right, #14163c 0%, #03217b 79%);
  background-color: ${appColors.defaultRuler};
  backdrop-filter: blur(25px);
`;

export const ForgotPassword = styled.h4`
  cursor: pointer;
`;

export const GeoLocationBoxContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GeoLocationBox = styled.div`
  height: 300px;
  width: 300px;
  padding: 20px;
  box-shadow: 0 0 10px #ddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  i {
    font-size: 100px;
    color: ${appColors.defaultFont};
  }
  p {
    font-size: 16px;
    text-align: center;
    color: ${appColors.defaultFont};
  }
`;
