import React from "react";
import {
  LoginFormContainer,
  WelcomeText,
  Container,
  HorizontalRule,
  HeaderContainer,
} from "../../views/styled/login/LoginFormView";
import InputBox from "../../views/elements/input/InputBox";
import {
  SubmitButton,
  SubmitButtonContainer,
  SubmitContainer,
} from "../../views/styled/forms/FormComponents";
import MessageToast from "../../views/elements/toast/MesageToast";

interface LoginTypes {
  email: any;
  password: any;
  onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  error: string;
  formError: boolean;
  clearMessages: () => void;
}

export default function LoginContainer({
  email,
  password,
  onEmailChange,
  onPasswordChange,
  onSubmit,
  error,
  formError,
  clearMessages,
}: LoginTypes) {
  return (
    <Container>
      <LoginFormContainer>
        <HeaderContainer>
          <WelcomeText>Login</WelcomeText>
        </HeaderContainer>
        <HeaderContainer>
          <HorizontalRule />
        </HeaderContainer>
        <InputBox
          type="email"
          label="Email"
          msg={email.msg}
          value={email.value}
          placeholder="Enter Email"
          onChange={onEmailChange}
          onBlur={onEmailChange}
        />

        <InputBox
          type="password"
          label="Password"
          msg={password.msg}
          value={password.value}
          placeholder="Enter Password"
          onChange={onPasswordChange}
          onBlur={onPasswordChange}
        />

        <SubmitContainer>
          <SubmitButtonContainer>
            <SubmitButton onClick={() => onSubmit()} disabled={formError}>
              Login
            </SubmitButton>
          </SubmitButtonContainer>
        </SubmitContainer>
      </LoginFormContainer>

      <MessageToast
        error={error}
        successMessage={""}
        clearMessages={clearMessages}
      />
    </Container>
  );
}
