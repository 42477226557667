import React, { useEffect, useState } from "react";
import {
  TabContainer,
  TabTextArea,
} from "../../../../../../../views/styled/message/MessagesViews";
import {
  SubmitButton,
  SubmitButtonContainer,
} from "../../../../../../../views/styled/forms/FormComponents";

interface Contenttypes {
  value: string;
  onSubmit: (message: string) => void;
}

export default function TabContent({ value, onSubmit }: Contenttypes) {
  const [inputValue, setInputValue] = useState("");

  const changeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <TabContainer>
      <TabTextArea
        name="postContent"
        value={inputValue}
        onChange={changeInput}
      />
      <SubmitButtonContainer>
        <SubmitButton onClick={() => onSubmit(value)}>
          <i className="bi bi-whatsapp" /> Send to Whatsapp
        </SubmitButton>
      </SubmitButtonContainer>
    </TabContainer>
  );
}
