import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { apiCaller } from "../../api/ApiCaller";
import endPoint from "../../api/EndPoints";
import HeaderGenerate from "../../utils/Api/HeaderGenerate";
import Ls from "../../utils/localStorage/Ls";
import { setGymDetailsReducer } from "./GymSlice";

interface LoginState {
  isLoggedIn: boolean;
  isLoading?: boolean;
  accessToken: string;
  refreshToken: string;
  error?: string;
  successMessage: string;
}

const loginJson: any = Ls.getItem("login");
const initialState: LoginState = {
  isLoggedIn: loginJson?.isLoggedIn,
  isLoading: false,
  accessToken: loginJson?.accessToken,
  refreshToken: loginJson?.refreshToken,
  successMessage: "",
  error: "",
};

interface LoginTypes {
  params: object;
  navigate: any;
}
export const loginRequest = createAsyncThunk(
  "LOGIN_REQUEST",
  async ({ params, navigate }: LoginTypes, thunkAPI: any) => {
    try {
      const headers = HeaderGenerate();
      const loginResponse: any = await apiCaller(
        endPoint.login,
        "POST",
        params,
        headers,
      );

      const gymData = {
        userDetails: loginResponse.data.data,
        gymMessages: loginResponse.data.gymMessages,
      };
      thunkAPI.dispatch(setGymDetailsReducer(gymData));
      navigate("/");
      return loginResponse.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  },
);

export const logoutRequest = createAsyncThunk(
  "LOGOUT_REQUEST",
  async ({ params, navigate }: LoginTypes, thunkAPI: any) => {
    try {
      const headers = HeaderGenerate();
      const logoutResponse: any = await apiCaller(
        endPoint.logout,
        "POST",
        params,
        headers,
      );

      thunkAPI.dispatch(logout());
      navigate("/");
      return logoutResponse.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkAPI.dispatch(refreshUserTokenRequest(""));
      }
      return thunkAPI.rejectWithValue(error?.response);
    }
  },
);

export const refreshUserTokenRequest = createAsyncThunk(
  "REFRESH_USER_TOKEN_REQUEST",
  async (arg: string, thunkAPI: any) => {
    const { login } = thunkAPI.getState();
    const params = { refreshToken: login.refreshToken };
    try {
      const headers = HeaderGenerate();
      const refreshResponse: any = await apiCaller(
        endPoint.refreshUserToken,
        "POST",
        params,
        headers,
      );
      const refreshData = refreshResponse.data;
      thunkAPI.dispatch(changeAccessTokenreducer(refreshData));
    } catch (error: any) {
      thunkAPI.dispatch(logout());
    }
  },
);

interface SignUpTypes {
  params: any;
  navigate: any;
}

export const signUpRequest = createAsyncThunk(
  "SIGNUP_REQUEST",
  async ({ params, navigate }: SignUpTypes, thunkAPI: any) => {
    try {
      const hasImage = params?.image_gyms;
      const headers = HeaderGenerate(hasImage);
      const destination = params?.image_gyms
        ? endPoint.signUpWithImage
        : endPoint.signUp;
      const loginResponse: any = await apiCaller(
        destination,
        "POST",
        params,
        headers,
      );

      const gymData = {
        userDetails: loginResponse.data.data,
        gymMessages: loginResponse.data.gymMessages,
      };
      thunkAPI.dispatch(setGymDetailsReducer(gymData));
      navigate("/");
      return loginResponse.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  },
);

export const loginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    changeAccessTokenreducer: (state, action) => {
      state.accessToken = action.payload.accessToken;
      const loginObj: any = {
        isLoggedIn: true,
        accessToken: action.payload?.accessToken,
        refreshToken: action.payload?.refreshToken,
      };
      Ls.setItem("login", JSON.stringify(loginObj));
      window.location.reload();
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.isLoading = false;
      state.accessToken = "";
      state.refreshToken = "";
      state.error = "";
      Ls.removeItem("login");
      Ls.removeItem("user");
      Ls.removeItem("messages");
    },
    clearMessages: (state) => {
      state.error = "";
      state.successMessage = "";
    },
  },
  extraReducers: {
    [loginRequest.pending.type]: (state) => {
      state.isLoggedIn = false;
      state.isLoading = true;
      state.accessToken = "";
      state.error = "";
    },
    [loginRequest.fulfilled.type]: (state, action) => {
      state.isLoggedIn = true;
      state.isLoading = false;
      state.accessToken = action.payload?.accessToken;
      state.refreshToken = action.payload?.refreshToken;
      state.error = "";

      const loginObj: any = {
        isLoggedIn: true,
        accessToken: action.payload?.accessToken,
        refreshToken: action.payload?.refreshToken,
      };
      Ls.setItem("login", JSON.stringify(loginObj));
    },
    [loginRequest.rejected.type]: (state, action) => {
      state.isLoggedIn = false;
      state.isLoading = false;
      state.accessToken = "";
      state.refreshToken = "";
      state.error = action.payload?.data?.error;
    },

    [signUpRequest.pending.type]: (state) => {
      state.isLoggedIn = false;
      state.isLoading = true;
      state.accessToken = "";
      state.refreshToken = "";
      state.error = "";
    },
    [signUpRequest.fulfilled.type]: (state, action) => {
      state.isLoggedIn = true;
      state.isLoading = false;
      state.accessToken = action.payload?.accessToken;
      state.refreshToken = action.payload?.refreshToken;
      state.error = "";

      const loginObj: any = {
        isLoggedIn: true,
        accessToken: action.payload?.accessToken,
        refreshToken: action.payload?.refreshToken,
      };
      Ls.setItem("login", JSON.stringify(loginObj));
    },
    [signUpRequest.rejected.type]: (state, action) => {
      state.isLoggedIn = false;
      state.isLoading = false;
      state.accessToken = "";
      state.refreshToken = "";
      state.error = action.payload?.data?.error;
    },
  },
});

export const { logout, clearMessages, changeAccessTokenreducer } =
  loginSlice.actions;

export const loginState = (state: RootState) => state.login;

export default loginSlice.reducer;
