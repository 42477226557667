import {
  ContainerArea,
  ContentArea,
  EmptyArea,
  SubHeadingText,
} from "../../../views/styled/layout/CommonViews";
import FilterDropdown from "../../../views/elements/filter/FilterDropdown";
import MessageToast from "../../../views/elements/toast/MesageToast";
import LoadingModal from "../../../views/elements/modals/LoadingModal";
import FilterAreaButton from "../../../views/components/form/FilterAreaButton";
import ModalView from "../../../views/elements/modals/ModalView";
import UpdateClient from "./components/UpdateClient";
import NewPackage from "./components/NewPackage";
import MessageBox from "./components/Message/MessageBox";
import MessageButton from "./components/Message/MessageButton";
import DataTable from "../../components/table/DataTable/DataTable";
import {
  FilterItemContainer,
  FilterItemsContainer,
  FilterMenuBar,
  HeadingSection,
  PageHeading,
} from "../../../views/styled/filterbar/FilterViews";
import SelectAllCheckbox from "../../components/table/DataTable/components/SelectAllCheckBox";
import CustomSearchBar from "../../components/search/CustomSearchBar";
import BackButton from "../../../views/components/goback/BackButton";
import { ActionItems } from "./components/ActionItems";

interface ClientsTypes {
  clientsData: Array<object>;
  isLoading: boolean;
  error: string;
  filterItems: Array<object>;
  filterState: [string, (param: string) => void];
  selectState: [Array<any>];
  finalLoad: boolean;
  currentState: [number, (param: number) => void];
  limit: number;
  apiStatus: boolean;
  deleteClientsGroup: () => void;
  successMessage: string;
  clearMessages: () => void;
  selectAllHandle: (list: Array<object>) => void;
  addClients: (params: object) => void;
  updateModalState: [
    (param: any) => void,
    () => void,
    boolean,
    () => void,
    () => void,
    (action: boolean) => void,
    boolean
  ];
  newPackageModalState: [boolean, () => void];
  messageModalState: [boolean, () => void, (item: any) => void];
  onSearch: (value: string) => void;
  navigateToAdd: () => void;
  onEditPress: () => void;
  onDeletePress: () => void;
  onRenewPackagePress: () => void;
  onLongPress?: (item: any) => void;
}

export default function ClientsContainer({
  clientsData,
  isLoading,
  error,
  filterItems,
  filterState,
  selectState,
  finalLoad,
  currentState,
  limit,
  apiStatus,
  deleteClientsGroup,
  successMessage,
  clearMessages,
  selectAllHandle,
  updateModalState,
  newPackageModalState,
  messageModalState,
  onSearch,
  navigateToAdd,
  onEditPress,
  onDeletePress,
  onRenewPackagePress,
  onLongPress
}: ClientsTypes) {
  const [selectItems] = selectState;
  const [
    onClickItem,
    onNewPackageModalShow,
    clientsUpdateModalVisible,
    onUpdateModalClose,
    clearUpdate
    , onToggleShowActionItems, actionItemModalShow
  ] = updateModalState;
  const [newPackageModalShow, onNewPackageModalClose] = newPackageModalState;
  const [messageModalShow, onMessageModalClose, onMessageModalShow] =
    messageModalState;
  return (
    <ContainerArea>
      <ModalView
        modalShow={actionItemModalShow}
        onHide={() => onToggleShowActionItems(false)}
        modalTitle={`Select Action For ${selectItems[0]?.name}`}
      >
        <ActionItems
          onRenewClick={onNewPackageModalShow}
          onEditClick={onEditPress}
          onCancelClick={() => onToggleShowActionItems(false)}
          onMessageClick={()=>onMessageModalShow(selectItems[0].id_clients)}
        />
      </ModalView>

      <ModalView
        modalShow={clientsUpdateModalVisible}
        onHide={clearUpdate}
        modalTitle={"Update Client Details"}
      >
        <UpdateClient
          dataItem={selectItems.length > 0 ? selectItems[0] : {}}
          onNewPackageModalShow={onNewPackageModalShow}
        />
      </ModalView>

      <ModalView
        modalShow={newPackageModalShow}
        onHide={clearUpdate}
        modalTitle={"Renew Package"}
      >
        <NewPackage
          dataItem={selectItems.length > 0 ? selectItems[0] : {}}
          onPackageModalClose={clearUpdate}
        />
      </ModalView>

      <ModalView
        modalShow={messageModalShow}
        onHide={onMessageModalClose}
        modalTitle={"Send Message"}
      >
        <MessageBox
          selectedItem={selectItems.length > 0 ? selectItems[0] : {}}
        />
      </ModalView>

      <FilterMenuBar>
        <BackButton />
        <HeadingSection>
          {selectItems.length > 0 && (
            <SelectAllCheckbox
              tableData={clientsData}
              selectAllHandle={selectAllHandle}
              tableType={"clients"}
            />
          )}
          {/* <PageHeading>Clients</PageHeading> */}
        </HeadingSection>

        <FilterItemsContainer>
          <FilterItemContainer>
            <FilterDropdown
              filterItems={filterItems}
              filterState={filterState}
            />
          </FilterItemContainer>

          <FilterItemContainer>
            <FilterAreaButton
              onClick={navigateToAdd}
              name={"Add"}
              icon="bi bi-person-add"
            />
          </FilterItemContainer>

          {selectItems.length > 0 && (
            <FilterItemContainer>
              <FilterAreaButton
                name={"Delete"}
                onClick={onDeletePress}
                icon="bi bi-trash"
              />
            </FilterItemContainer>
          )}
        </FilterItemsContainer>
      </FilterMenuBar>

      {/* {selectItems.length > 0 && (
        <ActionBar><ActionItemsContainer>
          {selectItems.length === 1 &&
            <ActionItemContainer>
              <ActionAreaButton
                name={"Edit"}
                onClick={onEditPress}
                icon="bi bi-person-rolodex"
              />

            </ActionItemContainer>

          }

          {selectItems.length === 1 &&
            <ActionItemContainer>
              <ActionAreaButton
                name={"Renew"}
                onClick={onRenewPackagePress}
                icon="bi bi-calendar-month"
              />
            </ActionItemContainer>
          }
          <ActionItemContainer>
            <ActionAreaButton
              name={"Delete"}
              onClick={onDeletePress}
              icon="bi bi-trash"
            />
          </ActionItemContainer>
        </ActionItemsContainer></ActionBar>
      )} */}

      <CustomSearchBar onSearch={onSearch} />

      {!clientsData.length && (
        <EmptyArea>
          <SubHeadingText>No Clients to show.</SubHeadingText>
        </EmptyArea>
      )}

      <LoadingModal isLoading={isLoading} />
      <ContentArea>
        {clientsData.length > 0 && (
          <DataTable
            tableData={clientsData}
            currentState={currentState}
            finalLoad={finalLoad}
            isLoading={isLoading}
            limit={limit}
            tableType={"clients"}
            apiStatus={apiStatus}
            onClickItem={onClickItem}
            onLongPress={onLongPress}
          />
        )}

        <MessageToast
          error={error}
          successMessage={successMessage}
          clearMessages={clearMessages}
        />
      </ContentArea>
    </ContainerArea>
  );
}
