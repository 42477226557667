import styled from "styled-components/macro";
import { appColors } from "../../colours/colours";

export const TileContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 20px;
  @media screen and (min-width: 320px) {
    grid-template-columns: auto auto auto auto;
  }
  @media screen and (min-width: 480px) {
    grid-template-columns: auto auto auto auto auto;
  }
  @media screen and (min-width: 560px) {
    grid-template-columns: auto auto auto auto auto auto;
  }

  // @media screen and (min-width: 720px) {
  //   max-width: 22vw;
  //   min-width: 22vw;
  //   font-size: 16px;
  //   line-height: 16px;
  // }

  // @media screen and (min-width: 768px) {
  //   max-width: 20vw;
  //   min-width: 20vw;
  // }

  // @media screen and (min-width: 1024px) {
  //   max-width: 23vw;
  //   min-width: 23vw;
  // }
`;

export const TileItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background: ${appColors.defaultBackground};
`;

export const TileHeading = styled.h2`
  font-size: 12px;
  max-width: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  padding: 5px 0px 0px 0px;
  text-align: center;
  @media screen and (min-width: 480px) {
    font-size: 14px;
    max-width: 70px;
  }
  @media screen and (min-width: 560px) {
    max-width: 80px;
  }

  @media screen and (min-width: 768px) {
    padding: 10px 0px 0px 0px;
    max-width: 70px;
  }

  @media screen and (min-width: 1024px) {
    max-width: 110px;
  }
`;

export const TileIconContainer = styled.div`
  width: 50px;
  height: 50px;
  box-shadow: 0 0 10px #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  :hover {
    box-shadow: 0 0 10px #7a7d7d;
  }

  @media screen and (min-width: 480px) {
    width: 60px;
    height: 60px;
  }
  @media screen and (min-width: 560px) {
    width: 70px;
    height: 70px;
  }

  @media screen and (min-width: 768px) {
    width: 80px;
    height: 80px;
  }

  @media screen and (min-width: 1024px) {
    width: 100px;
    height: 100px;
  }
`;

export const TileIcon = styled.i`
  font-size: 28px;
  color: ${appColors.defaultFont};
  opacity: 0.7;
  @media screen and (min-width: 480px) {
    font-size: 38px;
  }
  @media screen and (min-width: 560px) {
    font-size: 48px;
  }

  @media screen and (min-width: 768px) {
    font-size: 58px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 68px;
  }
`;
