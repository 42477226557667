import React from "react";
import { TbodyItem } from "../../../../../views/styled/datatable/TableViews";
import SelectorCheckbox from "./SelectorCheckbox";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/hooks/hooks";
import { deleteListCreateReducer } from "../../../../../redux/slices/PackagesSlice";
import {
  ClientListAddressArea,
  ClientListDetailsArea,
  ClientListName,
  ClientListNameArea,
  ClientListPanel,
  ClientListPersonalArea,
  DataListImageContainer,
  DetailIcon,
  DetailText,
  IconDetailsArea,
  TbodyRowClient,
} from "../../../../../views/styled/dataTable/DataTableViews";
import moment from "moment";

interface PackageTypes {
  item: any;
  keyId: number;
  listLength: number;
  lastItemRef: any;
  onClickItem: (item: any) => void;
}
const PackageListElement = ({
  item,
  keyId,
  listLength,
  lastItemRef,
  onClickItem,
}: PackageTypes) => {
  const packages = useAppSelector((state) => state.packages);
  const dispatch = useAppDispatch();
  const { finalLoad, deleteItems } = packages;

  const deleteListCreate = (clientId: number) => {
    dispatch(deleteListCreateReducer(clientId));
  };

  return (
    <TbodyRowClient
      key={keyId}
      ref={listLength === keyId + 1 && !finalLoad ? lastItemRef : null}
      className={listLength === keyId + 1 ? "last-one" : "normal"}
    >
      <TbodyItem>
        <span>
          {/* <SelectorCheckbox
            dataId={parseInt(item.id_packages)}
            deleteListCreate={deleteListCreate}
            checked={
              deleteItems.find(
                (deleteItem) => deleteItem === parseInt(item.id_packages)
              )
                ? true
                : false
            }
          /> */}
        </span>
      </TbodyItem>

      <TbodyItem
        onClick={() => {
          onClickItem(item);
        }}
      >
        <ClientListPanel>
          <ClientListPersonalArea>
            <DataListImageContainer>
              <i className="bi bi-bag-dash"></i>
            </DataListImageContainer>
            <ClientListNameArea>
              <ClientListName>{item?.name}</ClientListName>
              <IconDetailsArea>
                <DetailIcon className="bi bi-calendar2-week" />
                <DetailText>{`${item.validity} days.`}</DetailText>
              </IconDetailsArea>
            </ClientListNameArea>
          </ClientListPersonalArea>

          <ClientListDetailsArea>
            <IconDetailsArea>
              <DetailIcon className="bi bi-currency-rupee" />
              <DetailText>{item?.amount}</DetailText>
            </IconDetailsArea>
          </ClientListDetailsArea>

          <ClientListAddressArea>
            <IconDetailsArea>
              <DetailIcon className="bi bi-chat-right-dots" />
              <DetailText>{item?.description}</DetailText>
            </IconDetailsArea>
          </ClientListAddressArea>
        </ClientListPanel>
      </TbodyItem>
    </TbodyRowClient>
  );
};

export default PackageListElement;
