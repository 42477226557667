import moment from "moment";
import React, { useEffect, useState } from "react";
import { apiCaller } from "../../../../api/ApiCaller";
import EndPoints from "../../../../api/EndPoints";
import HeaderGenerate from "../../../../utils/Api/HeaderGenerate";
import validator from "../../../../utils/validator/validator";
import {
  ButtonRed,
  SubmitButton,
  SubmitButtonContainer,
  SubmitContainer,
} from "../../../../views/styled/forms/FormComponents";
import InputBox from "../../../../views/elements/input/InputBox";
import PackageSelect from "../../../../views/elements/input/PackageSelect";
import PaymentSuccess from "./PaymentSuccess";
import { useAppDispatch } from "../../../../redux/hooks/hooks";
import {
  setErrorReducer,
  setLoadingReducer,
  setSuccessMessageReducer,
} from "../../../../redux/slices/ClientsSlice";
interface LoadingModaltypes {
  dataItem: any;
  onPackageModalClose: () => void;
}
export default function NewPackage({
  dataItem,
  onPackageModalClose,
}: LoadingModaltypes) {
  const dispatch = useAppDispatch();
  const [formError, setFormError] = useState(true);
  const setIsLoading = (param: boolean) => {
    dispatch(setLoadingReducer(param));
  };

  const setError = (message: string) => {
    dispatch(setErrorReducer(message));
  };

  const setSuccessMessage = (message: string) => {
    dispatch(setSuccessMessageReducer(message));
  };

  interface SuccessTypes {
    status: boolean;
    data: any;
  }
  const [onPaymentSuccess, setOnPaymentSuccess] = useState<SuccessTypes>({
    status: false,
    data: {},
  });
  const [packageID, setPackageId] = useState({
    value: 0,
    msg: "",
    valid: false,
  });

  const [paymentMethod, setPaymentMethod] = useState({
    value: "",
    msg: "",
    valid: false,
  });

  const [subscriptionDate, setSubscriptionDate] = useState({
    value: "",
    msg: "",
    valid: false,
  });

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    setOnPaymentSuccess({
      status: false,
      data: {},
    });

    setPackageId({
      value: 0,
      msg: "",
      valid: false,
    });

    setSubscriptionDate({
      value: moment().format("YYYY-MM-DD"),
      msg: "",
      valid: true,
    });

    setPaymentMethod({
      value: "",
      msg: "",
      valid: false,
    });

    setError("");
    setSuccessMessage("");
  };

  useEffect(() => {
    setPackageId({ value: dataItem?.id_packages, msg: "", valid: true });
  }, [dataItem]);

  const onPackageIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value: number = parseInt(e.target.value);
    setPackageId((prevPackageId) => {
      return { value, msg: prevPackageId.msg, valid: prevPackageId.valid };
    });

    if (!validator.isId(value)) {
      setPackageId((prevPackageId) => {
        return {
          value: prevPackageId.value,
          msg: "Invalid Package Id.",
          valid: false,
        };
      });
    } else {
      setPackageId((prevPackageId) => {
        return { value: prevPackageId.value, msg: "", valid: true };
      });
    }
  };

  const onSubscriptionDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSubscriptionDate((prevSubscriptionDate) => {
      return {
        value,
        msg: prevSubscriptionDate.msg,
        valid: prevSubscriptionDate.valid,
      };
    });
    if (validator.isDob(e.target.value)) {
      setSubscriptionDate((prevSubscriptionDate) => {
        return { value: prevSubscriptionDate.value, msg: "", valid: true };
      });
    } else {
      setSubscriptionDate((prevSubscriptionDate) => {
        return {
          value: prevSubscriptionDate.value,
          msg: "Invalid SubscriptionDate",
          valid: false,
        };
      });
    }
  };

  const onPaymentMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;
    setPaymentMethod((prevPaymentMethod) => {
      return {
        value,
        msg: prevPaymentMethod.msg,
        valid: prevPaymentMethod.valid,
      };
    });
    if (validator.specialCharChecker(value)) {
      setPaymentMethod((prevPaymentMethod) => {
        return {
          value: prevPaymentMethod.value,
          msg: "Invalid Payment Method.",
          valid: false,
        };
      });
    } else {
      setPaymentMethod((prevPaymentMethod) => {
        return { value: prevPaymentMethod.value, msg: "", valid: true };
      });
    }
  };

  useEffect(() => {
    if (packageID.valid && paymentMethod.valid && subscriptionDate.valid) {
      setFormError(false);
    } else {
      setFormError(true);
    }
  }, [packageID, paymentMethod, subscriptionDate]);

  const clearMessages = () => {
    setError("");
    setSuccessMessage("");
  };

  const onSubmit = async () => {
    if (!formError) {
      const paymentData: object = {
        id_packages: packageID.value.toString(),
        id_clients: dataItem?.id_clients.toString(),
        date: moment(subscriptionDate.value).format("YYYY-MM-DD hh:mm:ss"),
        payment_method: paymentMethod.value,
        status: "true",
      };
      setIsLoading(true);
      const headers = HeaderGenerate();
      try {
        const subscriptionsDataResponse: any = await apiCaller(
          EndPoints.payments,
          "POST",
          paymentData,
          headers,
        );
        setIsLoading(false);
        setSuccessMessage("Package upgraded succesfully.");
        setOnPaymentSuccess({
          status: true,
          data: subscriptionsDataResponse?.data?.data,
        });
      } catch (e: any) {
        setIsLoading(false);
        setFormError(e?.response?.data?.error);
      }
    } else {
      alert("wrong input");
    }
  };

  const paymentFormView = () => {
    return (
      <>
        <PackageSelect
          label="Select A Package"
          msg={packageID.msg}
          onChange={onPackageIdChange}
          value={packageID.value}
        />

        <InputBox
          type="date"
          label="Subscription Date"
          msg={subscriptionDate.msg}
          value={subscriptionDate.value}
          placeholder="Enter Subscription Date"
          onChange={onSubscriptionDateChange}
          onBlur={onSubscriptionDateChange}
        />

        <InputBox
          type="text"
          label="Payment Method"
          msg={paymentMethod.msg}
          value={paymentMethod.value}
          placeholder="Cash"
          onChange={onPaymentMethodChange}
          onBlur={onPaymentMethodChange}
        />

        <SubmitContainer>
          <SubmitButtonContainer>
            <SubmitButton onClick={() => onSubmit()} disabled={formError}>
              Renew Package
            </SubmitButton>
          </SubmitButtonContainer>

          <SubmitButtonContainer>
            <ButtonRed onClick={onPackageModalClose}>Close</ButtonRed>
          </SubmitButtonContainer>
        </SubmitContainer>
      </>
    );
  };

  return (
    <>
      {!onPaymentSuccess.status ? (
        paymentFormView()
      ) : (
        <PaymentSuccess
          onPaymentSuccess={onPaymentSuccess}
          dataItem={dataItem}
          setIsLoading={setIsLoading}
          setSuccessMessage={setSuccessMessage}
          setFormError={setError}
        />
      )}
    </>
  );
}
