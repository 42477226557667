import React from "react";
import { TbodyItem } from "../../../../../views/styled/datatable/TableViews";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/hooks/hooks";
import { deleteListCreateReducer } from "../../../../../redux/slices/PackagesSlice";
import {
  ClientListAddressArea,
  ClientListDetailsArea,
  ClientListName,
  ClientListNameArea,
  ClientListPanel,
  ClientListPersonalArea,
  DataListImageContainer,
  DetailIcon,
  DetailText,
  IconDetailsArea,
  TbodyRowClient,
} from "../../../../../views/styled/dataTable/DataTableViews";
import moment from "moment";
import DownloadButton from "./DownloadButton";

interface PackageTypes {
  item: any;
  keyId: number;
  listLength: number;
  lastItemRef: any;
  setLoading: (state: boolean) => void;
}
const SubscriptionListElement = ({
  item,
  keyId,
  listLength,
  lastItemRef,
  setLoading,
}: PackageTypes) => {
  const packages = useAppSelector((state) => state.packages);
  const dispatch = useAppDispatch();
  const { finalLoad, deleteItems } = packages;

  const deleteListCreate = (clientId: number) => {
    dispatch(deleteListCreateReducer(clientId));
  };

  return (
    <TbodyRowClient
      key={keyId}
      ref={listLength === keyId + 1 && !finalLoad ? lastItemRef : null}
      className={listLength === keyId + 1 ? "last-one" : "normal"}
    >
      <TbodyItem></TbodyItem>

      <TbodyItem>
        <ClientListPanel>
          <ClientListPersonalArea>
            <DataListImageContainer>
              <i className="bi bi-currency-rupee"></i>
            </DataListImageContainer>
            <ClientListNameArea>
              <ClientListName>{item?.name}</ClientListName>
              <IconDetailsArea>
                <DetailIcon className="bi bi-calendar2-week" />
                <DetailText>{`${moment(item?.date).format(
                  "DD-MM-YYYY",
                )}`}</DetailText>
              </IconDetailsArea>
            </ClientListNameArea>
          </ClientListPersonalArea>

          <ClientListAddressArea>
            <IconDetailsArea>
              <DetailIcon className="bi bi-currency-rupee" />
              <DetailText>{item?.payment_method}</DetailText>
            </IconDetailsArea>
          </ClientListAddressArea>

          <ClientListDetailsArea>
            <DownloadButton
              paymentId={item.id_payments}
              setLoading={setLoading}
            />
          </ClientListDetailsArea>
        </ClientListPanel>
      </TbodyItem>
    </TbodyRowClient>
  );
};

export default SubscriptionListElement;
