import React, { useRef, useState } from "react";
import { TbodyItem } from "../../../../../views/styled/datatable/TableViews";
import SelectorCheckbox from "./SelectorCheckbox";
import {
  useAppSelector,
} from "../../../../../redux/hooks/hooks";
import DatalistThumb from "./DataListThumb";
import dateCompare from "../../../../../utils/date/dateCompare";
import {
  BirthdayIcon,
  ClientIconArea,
  ClientListAddressArea,
  ClientListDetailsArea,
  ClientListName,
  ClientListNameArea,
  ClientListPanel,
  ClientListPersonalArea,
  DetailIcon,
  DetailText,
  IconDetailsArea,
  TbodyRowClient,
} from "../../../../../views/styled/dataTable/DataTableViews";
import moment from "moment";

interface ClientsTypes {
  item: any;
  keyId: number;
  listLength: number;
  lastItemRef: any;
  onClickItem: (item: any) => void;
  onLongPress?: (item: any) => void;
}
const ClientsListElement = ({
  item,
  keyId,
  listLength,
  lastItemRef,
  onClickItem,
  onLongPress
}: ClientsTypes) => {
  const delay = 1000;
  const clients = useAppSelector((state) => state.clients);
  const { finalLoad, selectItems } = clients;
  const [isLongPress, setIsLongPress] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const isTouchRef = useRef(false); // Tracks if the event is a touch event
  // handle logn press start

  const handlePressStart = (e: React.TouchEvent | React.MouseEvent) => {
    if (e.type === "touchstart") {
      isTouchRef.current = true; // Mark as touch event
    }

    setIsLongPress(false);
    timerRef.current = setTimeout(() => {
      setIsLongPress(true);
      onLongPress && onLongPress(item);
    }, delay);
  };

  const handlePressEnd = (e: React.TouchEvent | React.MouseEvent) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (!isLongPress && !isTouchRef.current) {
      // Prevent mouse click if it follows a touch
      onClickItem(item);
    
    }

    isTouchRef.current = false; // Reset touch flag
  };

  const handleTouchCancel = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    isTouchRef.current = false; // Reset touch flag
  };

  // Long pRess handler end

  const deleteListCreate = () => {
    onClickItem(item);
  };

  const birthday = dateCompare.isBirthday(item?.dob);

  return (
    <TbodyRowClient
      key={keyId}
      ref={listLength === keyId + 1 && !finalLoad ? lastItemRef : null}
      className={listLength === keyId + 1 ? "last-one" : "normal"}
    >
      <TbodyItem>
        <span>
          <SelectorCheckbox
            deleteListCreate={deleteListCreate}
            checked={
              selectItems.find(
                (deleteItem: any) =>
                  deleteItem.id_clients === parseInt(item.id_clients),
              )
                ? true
                : false
            }
          />
        </span>
      </TbodyItem>

      <TbodyItem 
       onMouseDown={(e) => handlePressStart(e)}
       onMouseUp={(e) => handlePressEnd(e)}
       onMouseLeave={handleTouchCancel}
       onTouchStart={(e) => handlePressStart(e)}
       onTouchEnd={(e) => handlePressEnd(e)}
       onTouchCancel={handleTouchCancel}
       >
        <ClientListPanel>
          <ClientListPersonalArea>
            <DatalistThumb
              imageSrc={item.image_clients}
              gender={item?.gender}
            />
            <ClientListNameArea>
              <ClientListName>{item?.name}</ClientListName>
              <IconDetailsArea>
                <DetailText>{item?.phone}</DetailText>
              </IconDetailsArea>
            </ClientListNameArea>
          </ClientListPersonalArea>

          <ClientListDetailsArea>
            <IconDetailsArea>
              <DetailIcon className="bi bi bi-phone" />
              <DetailText>{item?.phone}</DetailText>
            </IconDetailsArea>

            <IconDetailsArea>
            <DetailIcon className="bi bi-clock-history" />
            <DetailText>{moment(item?.subscription_end_date).format("YYYY-MMM-DD")}</DetailText>
            </IconDetailsArea>
          </ClientListDetailsArea>

          <ClientListAddressArea>
            <IconDetailsArea>
              <DetailIcon className="bi bi-pin-map" />
              <DetailText>{item?.address}</DetailText>
            </IconDetailsArea>
          </ClientListAddressArea>

          {birthday && (
            <ClientIconArea>
              <BirthdayIcon className="bi bi-gift" />
            </ClientIconArea>
          )}
        </ClientListPanel>
      </TbodyItem>
    </TbodyRowClient>
  );
};

export default ClientsListElement;
