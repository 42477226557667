import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../containers/login";
import SignUp from "../containers/signup";
import Dashboard from "../containers/dashboard";
import Clients from "../containers/clients/clientsList";
import AddClient from "../containers/clients/addClients";
import UpdateClient from "../containers/clients/updateClients";
import Package from "../containers/packages";
import Subscription from "../containers/subscriptions";
import Header from "../containers/header/Header";
import NotFound from "../views/notFound/NotFound";
import PrivateRoutes from "./PrivateRoutes";
export default function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Header />}>
            <Route index element={<Dashboard />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/clients/add" element={<AddClient />} />
            <Route path="/clients/edit" element={<UpdateClient />} />
            <Route path="/packages" element={<Package />} />
            <Route path="/subscriptions" element={<Subscription />} />
          </Route>
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
