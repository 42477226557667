import React from 'react'
import { TileContainer } from '../../../../views/styled/dashboard/DashBoardView';
import Tile from '../../../dashboard/components/Tile';

interface ActionTypes {
    onRenewClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=> void;
    onEditClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=> void;
    onCancelClick:(e: React.MouseEvent<HTMLDivElement, MouseEvent>)=> void;
    onMessageClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=> void;
  }

export const ActionItems =({onRenewClick, onEditClick, onCancelClick, onMessageClick}: ActionTypes) => {
  return (
    <TileContainer>

<Tile
            icon="bi bi-calendar-month"
            tileHeading="Renew Package"
            onClick={onRenewClick}
          />

<Tile
            icon="bi bi-person-rolodex"
            tileHeading="Edit Details"
            onClick={onEditClick}
          />
          <Tile
            icon="bi bi-whatsapp"
            tileHeading="Send"
            onClick={onMessageClick}
          />
    
<Tile
            icon="bi bi-x-octagon"
            tileHeading="Cancel"
            onClick={onCancelClick}
          />
    </TileContainer>
  )
}
