import React, { useState } from "react";
import {
  ContainerArea,
  ContentArea,
} from "../../views/styled/layout/CommonViews";
import { TileContainer } from "../../views/styled/dashboard/DashBoardView";
import Tile from "./components/Tile";

interface DashboardTypes {
  onSHowSideBar: () => void;
  navigate: (screen: string) => void;
}

export default function DashboardContainer({
  onSHowSideBar,
  navigate,
}: DashboardTypes) {
  return (
    <ContainerArea>
      {/* <MessageEditModal
        show={messageUpdateModalVisible}
        onUpdateModalClose={() => setMessagesUpdateModalVisible(false)}
      /> */}
      <ContentArea>
        <TileContainer>
          <Tile
            icon="bi bi-buildings"
            tileHeading="My Gym"
            onClick={onSHowSideBar}
          />

          <Tile
            icon="bi bi-people"
            tileHeading={`Clients`}
            onClick={() => navigate("/clients")}
          />

          <Tile
            icon="bi bi-bag-dash"
            tileHeading={"Packages"}
            onClick={() => navigate("/packages")}
          />

          <Tile
            icon="bi bi-currency-rupee"
            tileHeading={"Subscriptions"}
            onClick={() => navigate("/subscriptions")}
          />

          <Tile
            icon="bi bi-gear-wide"
            tileHeading={"Settings"}
            onClick={() => console.log("")}
          />

          {/* <Tile
            icon="bi bi-chat-right-dots"
            tileHeading="Edit Messages"
            onClick={() => setMessagesUpdateModalVisible(true)}
          /> */}
        </TileContainer>
      </ContentArea>
    </ContainerArea>
  );
}
