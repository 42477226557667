import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { apiCaller } from "../../api/ApiCaller";
import endPoint from "../../api/EndPoints";
import HeaderGenerate from "../../utils/Api/HeaderGenerate";
import { refreshUserTokenRequest } from "./LoginSlice";

interface SubscriptionsType {
  isLoading: boolean;
  error: string;
  subscriptionsData: Array<object>;
  finalLoad: boolean;
  limit: number;
  apiStatus: boolean;
  successMessage: string;
  currentPosition: number;
  filter: number;
  status: number;
  filterShadow: boolean;
}

const initialState: SubscriptionsType = {
  isLoading: false,
  error: "",
  subscriptionsData: [],
  finalLoad: false,
  limit: 20,
  apiStatus: false,
  successMessage: "",
  currentPosition: 0,
  filter: 1,
  status: 0,
  filterShadow: false,
};

export const subscriptionsDataRequest = createAsyncThunk(
  "SUBSCRIPTIONS_DATA_REQUEST",
  async (params: string, thunkAPI: any) => {
    thunkAPI.dispatch(setLoading(true));
    thunkAPI.dispatch(setApiStatus());
    try {
      const headers = HeaderGenerate();
      const subscriptionsDataResponse: any = await apiCaller(
        endPoint.payments + params,
        "GET",
        {},
        headers,
      );
      return subscriptionsDataResponse.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkAPI.dispatch(refreshUserTokenRequest(""));
      }
      return thunkAPI.rejectWithValue(error?.response);
    }
  },
);

export const subscriptionsSlice = createSlice({
  name: "Subscriptions",
  initialState,
  reducers: {
    clear: (state) => {
      state.isLoading = false;
      state.subscriptionsData = [];
      state.error = "";
      state.finalLoad = false;
    },
    setCurrentReducer: (state, action) => {
      state.currentPosition = action.payload;
    },
    setFilterReducer: (state, action) => {
      state.filter = action.payload;
    },
    setFilterShadow: (state) => {
      state.filterShadow = true;
    },
    setStatusReducer: (state, action) => {
      state.status = action.payload;
    },
    clearMessages: (state) => {
      state.error = "";
      state.successMessage = "";
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setApiStatus: (state) => {
      state.apiStatus = true;
    },
  },
  extraReducers: {
    [subscriptionsDataRequest.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.apiStatus = true;
    },
    [subscriptionsDataRequest.fulfilled.type]: (state, action) => {
      if (action?.payload?.data.length > 0) {
        const { subscriptionsData, limit } = current(state);
        if (action?.payload?.data.length < limit) {
          state.finalLoad = true;
        } else {
          state.finalLoad = false;
        }
        state.apiStatus = false;
        state.isLoading = false;
        const responseData = action?.payload?.data;
        state.subscriptionsData = [...subscriptionsData, ...responseData];
        state.error = "";
        state.filterShadow = false;
      } else {
        state.apiStatus = false;
        state.isLoading = false;
        state.error = "";
        state.finalLoad = true;
        state.filterShadow = false;
      }
    },
    [subscriptionsDataRequest.rejected.type]: (state, action) => {
      state.apiStatus = false;
      state.isLoading = false;
      state.filterShadow = false;
      state.error = action.payload?.data?.error;
    },
  },
});

export const {
  clear,
  setLoading,
  setApiStatus,
  clearMessages,
  setCurrentReducer,
  setFilterReducer,
  setStatusReducer,
  setFilterShadow,
} = subscriptionsSlice.actions;

export const packagesState = (state: RootState) => state.packages;

export default subscriptionsSlice.reducer;
