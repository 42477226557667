import React, { useEffect, useState } from "react";
import EndPoints from "../../../api/EndPoints";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/hooks";
import { gymsUpdateRequest } from "../../../redux/slices/GymSlice";
import validator from "../../../utils/validator/validator";
import {
  ClickEditSubmitContainer,
  SubmitButton,
} from "../../../views/styled/forms/FormComponents";
import CustomInputFile from "../../../views/components/form/fileUploader/CustomInputFile";
import {
  LogoutButton,
  LogoutButtonIcon,
  SideBarContents,
  SideBarDetailContainer,
  SideBarDetailIcon,
  SideBarDetailsContainer,
  SideBarError,
  SideBarGymName,
  SideBarLogoContainer,
  SideBarTextArea,
  SideBarTextEdit,
} from "../../../views/styled/sidebar/SideBarViews";
import { logout, logoutRequest } from "../../../redux/slices/LoginSlice";
import { useNavigate } from "react-router-dom";

export default function MyGymView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(true);
  const [edited, setEdited] = useState(false);
  const gyms = useAppSelector((state) => state.gyms);
  const { refreshToken } = useAppSelector((state) => state.login);

  interface gymtypes {
    userDetails: any;
  }
  const { userDetails }: gymtypes = gyms;

  const onLogout = () => {
    const params = { refreshToken };
    dispatch(logoutRequest({ params, navigate }));
  };

  interface SeletedImageType {
    value: File | null;
    msg: string;
    valid: boolean;
  }
  const [selectedImage, setSelectedImage] = useState<SeletedImageType>({
    value: null,
    msg: "",
    valid: false,
  });

  const [imagethumb, setImageThumb] = useState("");

  const [name, setName] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [location, setLocation] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [address, setAddress] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [email, setEmail] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [phone, setPhone] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [altPhone, setAltPhone] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [subscription, setSubscription] = useState({
    value: "",
    msg: "",
    valid: false,
  });

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    setImageThumb(
      userDetails?.image_gyms
        ? EndPoints.gymImageUrl + userDetails?.image_gyms
        : "",
    );

    setName((prevPackageId) => {
      return { value: userDetails?.name, msg: "", valid: true };
    });

    setAddress((prevPackageId) => {
      return { value: userDetails?.address, msg: "", valid: true };
    });

    setPhone((prevPackageId) => {
      return { value: userDetails?.phone, msg: "", valid: true };
    });

    setAltPhone((prevPackageId) => {
      return { value: userDetails?.alt_phone, msg: "", valid: true };
    });

    setEmail((prevPackageId) => {
      return { value: userDetails?.email, msg: "", valid: true };
    });

    setLocation((prevPackageId) => {
      return { value: userDetails?.location, msg: "", valid: true };
    });

    setSubscription((prevPackageId) => {
      return { value: userDetails?.subscription, msg: "", valid: true };
    });
  };

  useEffect(() => {
    if (
      name.valid &&
      location.valid &&
      address.valid &&
      phone.valid &&
      altPhone.valid &&
      subscription.valid &&
      email.valid
    ) {
      setError(false);
    } else {
      setError(true);
    }
  }, [name, address, location, phone, altPhone, email, subscription]);

  const onNameChange = (e: any) => {
    const value = e.target.value;
    setName((prevName) => {
      return { value, msg: prevName.msg, valid: prevName.valid };
    });
    if (!validator.isName(e.target.value)) {
      setName((prevName) => {
        return { value: prevName.value, msg: "Invalid Name", valid: false };
      });
    } else {
      setEdited(true);
      setName((prevName) => {
        return { value: prevName.value, msg: "", valid: true };
      });
    }
  };

  const onLocationChange = (e: any) => {
    const value = e.target.value;
    setLocation((prevLocation) => {
      return { value, msg: prevLocation.msg, valid: prevLocation.valid };
    });
    if (!validator.specialCharChecker(e.target.value)) {
      setEdited(true);
      setLocation((prevLocation) => {
        return { value: prevLocation.value, msg: "", valid: true };
      });
    } else {
      setLocation((prevLocation) => {
        return {
          value: prevLocation.value,
          msg: "Invalid Location",
          valid: false,
        };
      });
    }
  };

  const onAddressChange = (e: any) => {
    const value = e.target.value;
    setAddress((prevAddress) => {
      return { value, msg: prevAddress.msg, valid: prevAddress.valid };
    });
    if (!validator.specialCharChecker(e.target.value)) {
      setEdited(true);
      setAddress((prevAddress) => {
        return { value: prevAddress.value, msg: "", valid: true };
      });
    } else {
      setAddress((prevAddress) => {
        return {
          value: prevAddress.value,
          msg: "Invalid Address",
          valid: false,
        };
      });
    }
  };

  const onPhoneChange = (e: any) => {
    const value = e.target.value;
    setPhone((prevPhone) => {
      return { value, msg: prevPhone.msg, valid: prevPhone.valid };
    });
    if (validator.isMobileNum(e.target.value)) {
      setEdited(true);
      setPhone((prevPhone) => {
        return { value: prevPhone.value, msg: "", valid: true };
      });
    } else {
      setPhone((prevPhone) => {
        return {
          value: prevPhone.value,
          msg: "Invalid Phone Number",
          valid: false,
        };
      });
    }
  };

  const onAltPhoneChange = (e: any) => {
    const value = e.target.value;
    setAltPhone((prevAltPhone) => {
      return { value, msg: prevAltPhone.msg, valid: prevAltPhone.valid };
    });
    if (validator.isMobileNum(e.target.value)) {
      setEdited(true);
      setAltPhone((prevAltPhone) => {
        return { value: prevAltPhone.value, msg: "", valid: true };
      });
    } else {
      setAltPhone((prevAltPhone) => {
        return {
          value: prevAltPhone.value,
          msg: "Invalid Alternate Phone Number",
          valid: false,
        };
      });
    }
  };

  const onEmailChange = (e: any) => {
    const value = e.target.value;
    setEmail((prevEmail) => {
      return { value, msg: prevEmail.msg, valid: prevEmail.valid };
    });
    if (validator.isEmail(e.target.value)) {
      setEdited(true);
      setEmail((prevEmail) => {
        return { value: prevEmail.value, msg: "", valid: true };
      });
    } else {
      setEmail((prevEmail) => {
        return { value: prevEmail.value, msg: "Invalid Email", valid: false };
      });
    }
  };

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles: FileList | null = e.target.files;
    const selectedFile: File | null = selectedFiles ? selectedFiles[0] : null;
    if (selectedFile && validator.isImage(selectedFile?.type)) {
      setEdited(true);
      setImageThumb(URL.createObjectURL(selectedFile));
      setSelectedImage((prevImage) => {
        return { value: selectedFile, msg: "", valid: true };
      });
    } else {
      setImageThumb("");
      setSelectedImage((prevImage) => {
        return {
          value: null,
          msg: "Wrong image format.!(please upload .jpeg or .png)",
          valid: false,
        };
      });
    }
  };

  const onSubmit = () => {
    if (!error) {
      const gymsData: object = {
        image_gyms: selectedImage.value,
        name: name.value,
        location: location.value,
        address: address.value,
        phone: phone.value,
        alt_phone: altPhone.value,
        email: email.value,
        subscription: subscription.value,
        password: "",
        status: "true",
      };

      dispatch(gymsUpdateRequest(gymsData));
    } else {
      alert("wrong input");
    }
  };

  return (
    <SideBarContents>
      <SideBarLogoContainer>
        <CustomInputFile
          onChange={onSelectFile}
          selectedImage={imagethumb}
          error={selectedImage.msg}
        />
      </SideBarLogoContainer>

      <SideBarDetailsContainer>
        <LogoutButton onClick={onLogout}>
          <LogoutButtonIcon className="bi bi-box-arrow-left" />
        </LogoutButton>

        {name.msg && <SideBarError>*{name.msg}</SideBarError>}
        {email.msg && <SideBarError>*{email.msg}</SideBarError>}
        {phone.msg && <SideBarError>*{phone.msg}</SideBarError>}
        {altPhone.msg && <SideBarError>*{altPhone.msg}</SideBarError>}
        {address.msg && <SideBarError>*{address.msg}</SideBarError>}

        <SideBarGymName
          value={name.value}
          onChange={onNameChange}
          type="text"
        />

        <SideBarDetailContainer>
          <SideBarDetailIcon className="bi bi-envelope-at" />
          <SideBarTextEdit
            value={email.value}
            onChange={onEmailChange}
            type="text"
          />
        </SideBarDetailContainer>

        <SideBarDetailContainer>
          <SideBarDetailIcon className="bi bi-phone" />
          <SideBarTextEdit
            value={phone.value}
            onChange={onPhoneChange}
            type="text"
          />
        </SideBarDetailContainer>

        <SideBarDetailContainer>
          <SideBarDetailIcon className="bi bi-telephone-plus" />
          <SideBarTextEdit
            value={altPhone.value}
            onChange={onAltPhoneChange}
            type="text"
          />
        </SideBarDetailContainer>

        <SideBarDetailContainer>
          <SideBarDetailIcon className="bi bi-geo" />
          <SideBarTextArea value={address.value} onChange={onAddressChange} />
        </SideBarDetailContainer>

        {edited && !error && (
          <ClickEditSubmitContainer>
            <SubmitButton onClick={onSubmit}>Update gym Details</SubmitButton>
          </ClickEditSubmitContainer>
        )}
      </SideBarDetailsContainer>
    </SideBarContents>
  );
}
