import React from "react";
import { CustomCheckBox } from "../../../../../views/styled/forms/FormComponents";

interface SelectorTypes {
  checked: boolean;
  deleteListCreate: () => void;
}

const SelectorCheckbox = ({ deleteListCreate, checked }: SelectorTypes) => {
  const changeCheck = () => {
    deleteListCreate();
  };
  return (
    <>
      {checked && (
        <CustomCheckBox
          type="checkbox"
          checked={checked}
          onChange={changeCheck}
        />
      )}
    </>
  );
};

export default SelectorCheckbox;
