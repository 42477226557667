import styled from "styled-components/macro";
import { appColors } from "../../colours/colours";

export const ContainerArea = styled.div``;

export const ContentArea = styled.div`
  padding: 20px 20px 0px 20px;
  @media screen and (min-width: 768px) {
    padding: 30px 50px 20px 50px;
  }
`;

export const HeadingText = styled.h2`
  margin: 0px;
  color: ${appColors.defaultFont};
`;
export const HeadingArea = styled.div`
  padding: 10px 40px;
`;

export const SubHeadingText = styled.p`
  margin: 0px;
  color: ${appColors.defaultFont};
`;

export const SubHeadingArea = styled.div`
  padding: 0px 40px;
`;

export const EmptyArea = styled.div`
  display: flex;
  height: 90vh;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const SpinnerContainer = styled.div`
  height: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorToastContainer = styled.div`
  position: fixed;
  bottom: 40px;
  left: 40px;
  z-index: 200000 !important;
`;
