import moment from "moment";
import React, { useEffect, useState } from "react";
import validator from "../../../utils/validator/validator";
import EndPoints from "../../../api/EndPoints";
import { apiCaller } from "../../../api/ApiCaller";
import HeaderGenerate from "../../../utils/Api/HeaderGenerate";
// import PaymentSuccess from "./PaymentSuccess";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/hooks";
import {
  clearMessages,
  setErrorReducer,
  setLoadingReducer,
  setSuccessMessageReducer,
} from "../../../redux/slices/ClientsSlice";
import AddClientsContainer from "./AddClientsContainer";
import { useNavigate } from "react-router-dom";

export default function AddClient() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const clients = useAppSelector((state) => state.clients);

  const { successMessage, error } = clients;

  const setError = (message: string) => {
    dispatch(setErrorReducer(message));
  };

  const setSuccessMessage = (message: string) => {
    dispatch(setSuccessMessageReducer(message));
  };

  const setIsLoading = (message: boolean) => {
    dispatch(setLoadingReducer(message));
  };

  // PaymentSuccess section
  const [formError, setFormError] = useState(false);
  interface SuccessTypes {
    status: boolean;
    data: any;
  }
  const [onPaymentSuccess, setOnPaymentSuccess] = useState<SuccessTypes>({
    status: false,
    data: {},
  });
  // payment success end

  const genderOptions: Array<object> = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "other" },
  ];

  interface SeletedImageType {
    value: File | null;
    msg: string;
    valid: boolean;
  }
  const [selectedImage, setSelectedImage] = useState<SeletedImageType>({
    value: null,
    msg: "",
    valid: false,
  });

  const [imagethumb, setImageThumb] = useState("");

  const [packageID, setPackageId] = useState({
    value: 0,
    msg: "",
    valid: false,
  });
  const [name, setName] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [address, setAddress] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [phone, setPhone] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [gender, setGender] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [dob, setDob] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [email, setEmail] = useState({
    value: "",
    msg: "",
    valid: false,
  });
  const [weight, setWeight] = useState({
    value: 0,
    msg: "",
    valid: false,
  });
  const [height, setHeight] = useState({
    value: 0,
    msg: "",
    valid: false,
  });
  const [disease, setDisease] = useState({
    value: "none",
    msg: "",
    valid: true,
  });
  const [subscriptionDate, setSubscriptionDate] = useState({
    value: moment().format("YYYY-MM-DD"),
    msg: "",
    valid: true,
  });
  const [disability, setDisability] = useState({
    value: "none",
    msg: "",
    valid: true,
  });

  const [paymentMethod, setPaymentMethod] = useState({
    value: "Cash",
    msg: "",
    valid: true,
  });

  useEffect(() => {
    if (
      packageID.valid &&
      name.valid &&
      address.valid &&
      phone.valid &&
      gender.valid &&
      dob.valid &&
      email.valid &&
      height.valid &&
      weight.valid &&
      disability.valid &&
      disease.valid &&
      subscriptionDate.valid &&
      paymentMethod.valid
    ) {
      setFormError(false);
    } else {
      setFormError(true);
    }
  }, [
    packageID,
    name,
    address,
    phone,
    gender,
    dob,
    email,
    height,
    weight,
    disability,
    disease,
    subscriptionDate,
    paymentMethod,
  ]);

  const onPackageIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value: number = parseInt(e.target.value);
    setPackageId((prevPackageId) => {
      return { value, msg: prevPackageId.msg, valid: prevPackageId.valid };
    });
    if (!validator.isId(value)) {
      setPackageId((prevPackageId) => {
        return {
          value: prevPackageId.value,
          msg: "Invalid Package Id.",
          valid: false,
        };
      });
    } else {
      setPackageId((prevPackageId) => {
        return { value: prevPackageId.value, msg: "", valid: true };
      });
    }
  };

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName((prevName) => {
      return { value, msg: prevName.msg, valid: prevName.valid };
    });
    if (!validator.isName(e.target.value)) {
      setName((prevName) => {
        return { value: prevName.value, msg: "Invalid Name", valid: false };
      });
    } else {
      setName((prevName) => {
        return { value: prevName.value, msg: "", valid: true };
      });
    }
  };

  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAddress((prevAddress) => {
      return { value, msg: prevAddress.msg, valid: prevAddress.valid };
    });
    if (!validator.specialCharChecker(e.target.value)) {
      setAddress((prevAddress) => {
        return { value: prevAddress.value, msg: "", valid: true };
      });
    } else {
      setAddress((prevAddress) => {
        return {
          value: prevAddress.value,
          msg: "Invalid Address",
          valid: false,
        };
      });
    }
  };

  const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhone((prevPhone) => {
      return { value, msg: prevPhone.msg, valid: prevPhone.valid };
    });
    if (validator.isMobileNum(e.target.value)) {
      setPhone((prevPhone) => {
        return { value: prevPhone.value, msg: "", valid: true };
      });
    } else {
      setPhone((prevPhone) => {
        return {
          value: prevPhone.value,
          msg: "Invalid Phone Number",
          valid: false,
        };
      });
    }
  };

  const onGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setGender((prevGender) => {
      return { value, msg: prevGender.msg, valid: prevGender.valid };
    });
    if (!validator.isGender(e.target.value)) {
      setGender((prevGender) => {
        return { value: prevGender.value, msg: "Invalid Gender", valid: false };
      });
    } else {
      setGender((prevGender) => {
        return { value: prevGender.value, msg: "", valid: true };
      });
    }
  };

  const onDobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const format = moment(value).format("YYYY-MM-DD");
    setDob((prevDob) => {
      return { value: format, msg: prevDob.msg, valid: prevDob.valid };
    });
    if (validator.isDob(format)) {
      setDob((prevDob) => {
        return { value: prevDob.value, msg: "", valid: true };
      });
    } else {
      setDob((prevDob) => {
        return { value: prevDob.value, msg: "Invalid Dob", valid: false };
      });
    }
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail((prevEmail) => {
      return { value, msg: prevEmail.msg, valid: prevEmail.valid };
    });
    if (validator.isEmail(e.target.value)) {
      setEmail((prevEmail) => {
        return { value: prevEmail.value, msg: "", valid: true };
      });
    } else {
      setEmail((prevEmail) => {
        return { value: prevEmail.value, msg: "Invalid Email", valid: false };
      });
    }
  };

  const onWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: number = parseInt(e.target.value);
    setWeight((prevWeight) => {
      return { value, msg: prevWeight.msg, valid: prevWeight.valid };
    });
    if (validator.isWeight(e.target.value)) {
      setWeight((prevWeight) => {
        return { value: prevWeight.value, msg: "", valid: true };
      });
    } else {
      setWeight((prevWeight) => {
        return { value: prevWeight.value, msg: "Invalid Weight", valid: false };
      });
    }
  };

  const onHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: number = parseInt(e.target.value);
    setHeight((prevHeight) => {
      return { value, msg: prevHeight.msg, valid: prevHeight.valid };
    });
    if (validator.isHeight(e.target.value)) {
      setHeight((prevHeight) => {
        return { value: prevHeight.value, msg: "", valid: true };
      });
    } else {
      setHeight((prevHeight) => {
        return { value: prevHeight.value, msg: "Invalid Height", valid: false };
      });
    }
  };

  const onDiseaseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDisease((prevDisease) => {
      return { value, msg: prevDisease.msg, valid: prevDisease.valid };
    });
    if (!validator.specialCharChecker(e.target.value)) {
      setDisease((prevDisease) => {
        return { value: prevDisease.value, msg: "", valid: true };
      });
    } else {
      setDisease((prevDisease) => {
        return {
          value: prevDisease.value,
          msg: "Invalid Disease",
          valid: false,
        };
      });
    }
  };

  const onSubscriptionDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSubscriptionDate((prevSubscriptionDate) => {
      return {
        value,
        msg: prevSubscriptionDate.msg,
        valid: prevSubscriptionDate.valid,
      };
    });
    if (validator.isDob(e.target.value)) {
      setSubscriptionDate((prevSubscriptionDate) => {
        return { value: prevSubscriptionDate.value, msg: "", valid: true };
      });
    } else {
      setSubscriptionDate((prevSubscriptionDate) => {
        return {
          value: prevSubscriptionDate.value,
          msg: "Invalid SubscriptionDate",
          valid: false,
        };
      });
    }
  };

  const onDisabilityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDisability((prevDisability) => {
      return { value, msg: prevDisability.msg, valid: prevDisability.valid };
    });
    if (!validator.specialCharChecker(e.target.value)) {
      setDisability((prevDisability) => {
        return { value: prevDisability.value, msg: "", valid: true };
      });
    } else {
      setDisability((prevDisability) => {
        return {
          value: prevDisability.value,
          msg: "Invalid Disability",
          valid: false,
        };
      });
    }
  };

  const onPaymentMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;
    setPaymentMethod((prevPaymentMethod) => {
      return {
        value,
        msg: prevPaymentMethod.msg,
        valid: prevPaymentMethod.valid,
      };
    });
    if (validator.specialCharChecker(value)) {
      setPaymentMethod((prevPaymentMethod) => {
        return {
          value: prevPaymentMethod.value,
          msg: "Invalid Payment Method.",
          valid: false,
        };
      });
    } else {
      setPaymentMethod((prevPaymentMethod) => {
        return { value: prevPaymentMethod.value, msg: "", valid: true };
      });
    }
  };

  const closePaymentSuccess = () => {
    setOnPaymentSuccess({ status: false, data: {} });
    navigate("/clients");
  };

  const closeAddClient = () => {
    setOnPaymentSuccess({ status: false, data: {} });
    navigate("/clients");
  };

  const clearAllMessages = () => {
    dispatch(clearMessages());
  };

  const clearForm = () => {
    setPackageId({
      value: 0,
      msg: "",
      valid: false,
    });
    setName({
      value: "",
      msg: "",
      valid: false,
    });
    setAddress({
      value: "",
      msg: "",
      valid: false,
    });
    setPhone({
      value: "",
      msg: "",
      valid: false,
    });
    setGender({
      value: "",
      msg: "",
      valid: false,
    });
    setDob({
      value: "",
      msg: "",
      valid: false,
    });
    setEmail({
      value: "",
      msg: "",
      valid: false,
    });
    setWeight({
      value: 0,
      msg: "",
      valid: false,
    });
    setHeight({
      value: 0,
      msg: "",
      valid: false,
    });
    setDisease({
      value: "none",
      msg: "",
      valid: true,
    });
    setSubscriptionDate({
      value: moment().format("YYYY-MM-DD"),
      msg: "",
      valid: true,
    });
    setDisability({
      value: "none",
      msg: "",
      valid: true,
    });
  };

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles: FileList | null = e.target.files;
    const selectedFile: File | null = selectedFiles ? selectedFiles[0] : null;
    if (selectedFile && validator.isImage(selectedFile?.type)) {
      setImageThumb(URL.createObjectURL(selectedFile));
      setSelectedImage((prevImage) => {
        return { value: selectedFile, msg: "", valid: true };
      });
    } else {
      setImageThumb("");
      setSelectedImage((prevImage) => {
        return {
          value: null,
          msg: "Wrong image format.!(please upload .jpeg or .png)",
          valid: false,
        };
      });
    }
  };

  const onSubmit = async () => {
    if (!formError) {
      const clientsData: any = {
        image_client: selectedImage.value,
        id_packages: packageID.value.toString(),
        name: name.value,
        address: address.value,
        phone: phone.value,
        gender: gender.value,
        dob: dob.value,
        email: email.value,
        weight: weight.value.toString(),
        height: height.value.toString(),
        disability: disability.value,
        diseases: disease.value,
        subscription_date: moment(subscriptionDate.value).format(
          "YYYY-MM-DD hh:mm:ss",
        ),
        payment_method: paymentMethod.value,
        status: "true",
      };

      try {
        const hasImage = clientsData?.image_client;
        const headers = HeaderGenerate(hasImage);
        const destination = clientsData?.image_client
          ? EndPoints.clientsWithImage
          : EndPoints.clients;
        const clientsAddResponse: any = await apiCaller(
          destination,
          "POST",
          clientsData,
          headers,
        );

        // setSuccessMessage("Package upgraded succesfully.");
        setOnPaymentSuccess({
          status: true,
          data: clientsAddResponse?.data?.data,
        });
      } catch (e: any) {
        // setIsLoading(false);
        // setFormError(e?.response?.data?.error);
        setOnPaymentSuccess({
          status: true,
          data: {},
        });
      }
    } else {
      alert("wrong input");
    }
  };

  return (
    <AddClientsContainer
      selectImageState={[selectedImage, imagethumb, onSelectFile]}
      packageState={[packageID, onPackageIdChange]}
      nameState={[name, onNameChange]}
      addressState={[address, onAddressChange]}
      phoneState={[phone, onPhoneChange]}
      genderState={[gender, onGenderChange]}
      genderOptions={genderOptions}
      dobState={[dob, onDobChange]}
      emailState={[email, onEmailChange]}
      heightState={[height, onHeightChange]}
      weightState={[weight, onWeightChange]}
      diseaseState={[disease, onDiseaseChange]}
      disabilityState={[disability, onDisabilityChange]}
      subscriptionDateState={[subscriptionDate, onSubscriptionDateChange]}
      paymentMethodState={[paymentMethod, onPaymentMethodChange]}
      onSubmit={onSubmit}
      formError={formError}
      clearForm={clearForm}
      successState={[successMessage, setSuccessMessage]}
      clearMessages={clearAllMessages}
      paymentSuccessState={[onPaymentSuccess, closePaymentSuccess]}
      errorState={[error, setError]}
      setIsLoading={setIsLoading}
      closeAddClient={closeAddClient}
    />
  );
}
