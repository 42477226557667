import styled from "styled-components/macro";
import { appColors } from "../../colours/colours";
import { HeadingText } from "../layout/CommonViews";

export const NavBarContainer = styled.div`
  width: 100%;
  min-height: 40px;
  padding: 10px 20px;
  border-bottom: 1px solid ${appColors.defaultBorder};
  box-shadow: 0 0 10px #ddd;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  margin: 0;
  @media screen and (min-width: 720px) {
    padding: 10px 50px;
  }
`;

export const NavButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NavButton = styled.button`
  background-color: ${appColors.defaultBackground};
  height: 30px;
  width: 30px;
  border: none;
  color: white;
  padding: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 0 10px #ddd;
`;

export const NavButtonIcon = styled.i`
  font-size: 22px;
  line-height: 22px;
  color: ${appColors.defaultFont};
  padding: 0;
  margin: 0;
`;

export const NavHeadingSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;
  width: 100%;
  overflow: hidden;
`;

export const NavAppHeading = styled(HeadingText)`
  font-size: 22px;
  color: ${appColors.defaultFont};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CustomLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px #ddd;
  border-radius: 5px;
`;
