import React, { useState } from "react";
import {
  CustomCheckBox,
  SelectAllCheckBoxContainer,
} from "../../../../../views/styled/forms/FormComponents";

interface SelectorTypes {
  tableData: Array<object>;
  selectAllHandle: (list: Array<object>) => void;
  tableType: string;
}

const SelectAllCheckbox = ({
  tableData,
  selectAllHandle,
  tableType,
}: SelectorTypes) => {
  const [check, setCheck] = useState(false);
  const onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      selectAllHandle(tableData);
      setCheck(true);
    } else {
      const selectArray: Array<object> = [];
      selectAllHandle(selectArray);
      setCheck(false);
    }
  };
  return (
    <SelectAllCheckBoxContainer>
      <CustomCheckBox type="checkbox" checked={check} onChange={onSelect} />
    </SelectAllCheckBoxContainer>
  );
};

export default SelectAllCheckbox;
