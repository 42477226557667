import styled from "styled-components/macro";
import { appColors } from "../../colours/colours";

export const DataListImage = styled.img`
  height: 100%;
  width: 100%;
`;

export const DataListImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background: ${appColors.defaultBackground};
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 10px #ddd;
  @media screen and (min-width: 480px) {
    height: 50px;
    width: 50px;
    border-radius: 25px;
  }
`;

// clients list styles

export const TbodyRowClient = styled.td`
  position: relative;
  background: ${appColors.defaultBackground};
  box-shadow: 0px 0px 2px #ddd;
  display: flex;
  flexdirection: row;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px 10px;
  overflow: hidden;
  max-width: 100%;
  min-width: 100%;
  &:hover {
    border-bottom: solid 2px ${appColors.defaultBorder};
  }
  @media screen and (min-width: 768px) {
    margin-bottom: 30px;
  }
`;

export const ClientListPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ClientListPersonalArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;
  @media screen and (min-width: 360px) {
    padding: 0px 10px;
  }
`;

export const ClientListNameArea = styled.div`
  padding-left: 10px;
  overflow: hidden;
`;

export const ClientListDetailsArea = styled.div`
  padding: 0px 10px;
  max-height: 50px;
  overflow: hidden;
`;

export const ClientListAddressArea = styled.div`
  padding: 0px 20px;
  max-height: 50px;
  overflow: hidden;
  display: none;
  @media screen and (min-width: 480px) {
    display: block;
  }
`;

export const ClientListName = styled.p`
  color: ${appColors.defaultFont};
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  max-width: 22vw;
  min-width: 22vw;
  defaultbackground-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (min-width: 480px) {
    max-width: 16vw;
    min-width: 16vw;
  }

  @media screen and (min-width: 560px) {
    max-width: 19vw;
    min-width: 19vw;
  }

  @media screen and (min-width: 720px) {
    max-width: 22vw;
    min-width: 22vw;
    font-size: 16px;
  }

  @media screen and (min-width: 768px) {
    max-width: 16vw;
    min-width: 16vw;
  }
`;

export const IconDetailsArea = styled.div`
  display: flex;
  flexdirection: row;
  align-items: center;
  padding: 0px 0px;
`;

export const DetailIcon = styled.i`
  font-size: 12px;
  line-height: 12px;
  margin: 0;
  padding: 0px 5px 0px 0px;

  @media screen and (min-width: 720px) {
    font-size: 16px;
    line-height: 16px;
  }
`;

export const DetailText = styled.p`
  font-size: 12px;
  margin: 0;
  padding: 0px 0px 0px 0px;
  font-style: italic;
  max-width: 18vw;
  min-width: 18vw;
  overflow: hidden;
  text-overflow: ellipsis;
  defaultbackground-space: nowrap;
  @media screen and (min-width: 320px) {
    max-width: 24vw;
    min-width: 24vw;
  }
  @media screen and (min-width: 360px) {
    max-width: 25vw;
    min-width: 25vw;
  }
  @media screen and (min-width: 420px) {
    max-width: 29vw;
    min-width: 29vw;
  }

  @media screen and (min-width: 480px) {
    max-width: 18vw;
    min-width: 18vw;
  }
  @media screen and (min-width: 560px) {
    max-width: 21vw;
    min-width: 21vw;
  }

  @media screen and (min-width: 720px) {
    max-width: 22vw;
    min-width: 22vw;
    font-size: 16px;
  }

  @media screen and (min-width: 768px) {
    max-width: 20vw;
    min-width: 20vw;
  }

  @media screen and (min-width: 1024px) {
    max-width: 23vw;
    min-width: 23vw;
  }
`;

export const ClientIconArea = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const BirthdayIcon = styled.i`
  font-size: 10px;
  line-height: 10px;
  margin: 0;
  padding: 0;
  @media screen and (min-width: 720px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const DownloadButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DownloadButtonView = styled.button`
  max-width: 150px;
  padding: 5px 5px;
  box-shadow: 0 0 10px #ddd;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${appColors.defaultBackground};
  border: none;
  i {
    font-size: 12px;
    line-height: 12px;
    @media screen and (min-width: 720px) {
      font-size: 16px;
      line-height: 16px;
    }
  }
`;

export const DownloadButtonText = styled.p`
  font-size: 12px;
  line-height: 12px;
  margin: 0;
  padding: 0px 5px 0px 0px;
  font-style: italic;
  @media screen and (min-width: 868px) {
    font-size: 16px;
    line-height: 16px;
  }
`;
