import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { apiCaller } from "../../api/ApiCaller";
import endPoint from "../../api/EndPoints";
import HeaderGenerate from "../../utils/Api/HeaderGenerate";
import { refreshUserTokenRequest } from "./LoginSlice";

interface ClientsType {
  isLoading: boolean;
  packagesData: Array<object>;
  allPackagesData: Array<object>;
  error: string;
  finalLoad: boolean;
  limit: number;
  apiStatus: boolean;
  successMessage: string;
  currentPosition: number;
  filter: number;
  status: number;
  deleteItems: Array<number>;
  filterShadow: boolean;
  addModalVisible: boolean;
  packagesUpdateModalVisible: boolean;
}

const initialState: ClientsType = {
  isLoading: false,
  packagesData: [],
  allPackagesData: [],
  error: "",
  finalLoad: false,
  limit: 20,
  apiStatus: false,
  successMessage: "",
  currentPosition: 0,
  filter: 1,
  status: 0,
  deleteItems: [],
  filterShadow: false,
  addModalVisible: false,
  packagesUpdateModalVisible: false,
};

export const packagesDataRequest = createAsyncThunk(
  "PACKAGES_DATA_REQUEST",
  async (params: string, thunkAPI: any) => {
    thunkAPI.dispatch(setLoading());
    thunkAPI.dispatch(setApiStatus());
    try {
      const headers = HeaderGenerate();
      const packagesDataResponse: any = await apiCaller(
        endPoint.packages + params,
        "GET",
        {},
        headers,
      );
      return packagesDataResponse.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkAPI.dispatch(refreshUserTokenRequest(""));
      }
      return thunkAPI.rejectWithValue(error?.response);
    }
  },
);

export const allPackagesDataRequest = createAsyncThunk(
  "PACKAGES_ALL_DATA_REQUEST",
  async (params, thunkAPI: any) => {
    thunkAPI.dispatch(setLoading());
    try {
      const headers = HeaderGenerate();
      const packagesDataResponse: any = await apiCaller(
        endPoint.packages,
        "GET",
        {},
        headers,
      );
      return packagesDataResponse.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkAPI.dispatch(refreshUserTokenRequest(""));
      }
      return thunkAPI.rejectWithValue(error?.response);
    }
  },
);

export const packagesDeleteRequest = createAsyncThunk(
  "PACKAGES_DELETE_REQUEST",
  async (params: object, thunkAPI: any) => {
    thunkAPI.dispatch(setLoading());
    try {
      const headers = HeaderGenerate();
      const packagesDeleteResponse: any = await apiCaller(
        endPoint.deletePackages,
        "POST",
        params,
        headers,
      );
      const response = packagesDeleteResponse.data;
      const deleteData: object = { response, params };
      return deleteData;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkAPI.dispatch(refreshUserTokenRequest(""));
      }
      return thunkAPI.rejectWithValue(error?.response);
    }
  },
);

export const packagesAddRequest = createAsyncThunk(
  "PACKAGES_ADD_REQUEST",
  async (params: any, thunkAPI: any) => {
    thunkAPI.dispatch(setLoading());
    try {
      const headers = HeaderGenerate();
      const destination = endPoint.packages;
      const packagesAddResponse: any = await apiCaller(
        destination,
        "POST",
        params,
        headers,
      );
      return packagesAddResponse.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkAPI.dispatch(refreshUserTokenRequest(""));
      }
      return thunkAPI.rejectWithValue(error?.response);
    }
  },
);

export const packagesUpdateRequest = createAsyncThunk(
  "PACKAGES_UPDATE_REQUEST",
  async (params: any, thunkAPI: any) => {
    thunkAPI.dispatch(setLoading());
    try {
      const headers = HeaderGenerate();
      const destination = endPoint.packages;
      const packagesUpdateResponse: any = await apiCaller(
        destination,
        "PUT",
        params,
        headers,
      );
      return packagesUpdateResponse.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkAPI.dispatch(refreshUserTokenRequest(""));
      }
      return thunkAPI.rejectWithValue(error?.response);
    }
  },
);

export const packagesSlice = createSlice({
  name: "Packages",
  initialState,
  reducers: {
    clear: (state) => {
      state.isLoading = false;
      state.packagesData = [];
      state.error = "";
      state.finalLoad = false;
    },
    clearDeleteItems: (state) => {
      state.deleteItems = [];
    },
    setCurrentReducer: (state, action) => {
      state.currentPosition = action.payload;
    },
    setFilterReducer: (state, action) => {
      state.filter = action.payload;
    },
    setFilterShadow: (state) => {
      state.filterShadow = true;
    },
    setStatusReducer: (state, action) => {
      state.status = action.payload;
    },
    setDeleteItemsReducer: (state, action) => {
      state.deleteItems = action.payload;
    },
    deleteListCreateReducer: (state, action) => {
      const { deleteItems } = current(state);
      const clientId = action.payload;
      let tempItems: Array<number> = [...deleteItems];
      if (deleteItems.find((deleteItem: number) => deleteItem === clientId)) {
        const itemId = deleteItems.findIndex(
          (deleteItem: number) => deleteItem === clientId,
        );
        tempItems.splice(itemId, 1);
      } else {
        tempItems = [...deleteItems, clientId];
      }
      state.deleteItems = tempItems;
    },
    clearMessages: (state) => {
      state.error = "";
      state.successMessage = "";
    },
    clearDataReducer: (state) => {
      state.finalLoad = false;
      state.packagesData = [];
      state.currentPosition = 0;
      state.successMessage = "";
      state.error = "";
    },
    setLoading: (state) => {
      state.isLoading = true;
    },
    setApiStatus: (state) => {
      state.apiStatus = true;
    },
    setAddModalVisibleReducer: (state, action) => {
      state.addModalVisible = action.payload;
    },
    setPackagesUpdateModalVisibleReducer: (state, action) => {
      state.packagesUpdateModalVisible = action.payload;
    },
  },
  extraReducers: {
    [allPackagesDataRequest.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    [allPackagesDataRequest.fulfilled.type]: (state, action) => {
      state.allPackagesData = action?.payload?.data;
      state.isLoading = false;
      state.error = "";
    },
    [allPackagesDataRequest.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.data?.error;
    },
    [packagesDataRequest.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.apiStatus = true;
    },
    [packagesDataRequest.fulfilled.type]: (state, action) => {
      if (action?.payload?.data.length > 0) {
        const { packagesData, limit } = current(state);
        if (action?.payload?.data.length < limit) {
          state.finalLoad = true;
        } else {
          state.finalLoad = false;
        }
        state.apiStatus = false;
        state.isLoading = false;
        const responseData = action?.payload?.data;
        state.packagesData = [...packagesData, ...responseData];
        state.error = "";
        state.filterShadow = false;
      } else {
        state.apiStatus = false;
        state.isLoading = false;
        state.error = "";
        state.finalLoad = true;
        state.filterShadow = false;
      }
    },
    [packagesDataRequest.rejected.type]: (state, action) => {
      state.apiStatus = false;
      state.isLoading = false;
      state.filterShadow = false;
      state.error = action.payload?.data?.error;
    },

    [packagesDeleteRequest.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.apiStatus = true;
    },
    [packagesDeleteRequest.fulfilled.type]: (state, action) => {
      const { packagesData } = current(state);
      state.apiStatus = false;
      state.isLoading = false;
      state.error = "";
      state.deleteItems = [];
      state.successMessage = action?.payload?.response?.message;
      const deletedIds = action?.payload?.params?.ids.split(/,/);
      const removedpackagesdata = packagesData.filter(
        (packageData: any) =>
          !deletedIds.find(
            (id: any) => parseInt(id) === parseInt(packageData.id_packages),
          ),
      );
      state.packagesData = removedpackagesdata;
    },
    [packagesDeleteRequest.rejected.type]: (state, action) => {
      state.apiStatus = false;
      state.isLoading = false;
      state.successMessage = "";
      state.error = action.payload?.data?.error;
    },

    [packagesAddRequest.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.apiStatus = true;
    },
    [packagesAddRequest.fulfilled.type]: (state, action) => {
      const { packagesData } = current(state);
      state.apiStatus = false;
      state.isLoading = false;
      state.error = "";
      state.successMessage = "package Added Successfully.!";
      state.packagesData = [...packagesData, action?.payload?.data];
    },
    [packagesAddRequest.rejected.type]: (state, action) => {
      state.apiStatus = false;
      state.isLoading = false;
      state.successMessage = "";
      state.error = action.payload?.data?.error;
    },

    [packagesUpdateRequest.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.apiStatus = true;
    },
    [packagesUpdateRequest.fulfilled.type]: (state, action) => {
      const { packagesData } = current(state);
      state.apiStatus = false;
      state.isLoading = false;
      state.error = "";
      state.successMessage = "package Details Changed Successfully.!";
      const prevpackagesData = [...packagesData];
      const editedData = action?.payload?.data;
      const editedIndex = prevpackagesData.findIndex(
        (packageData: any) =>
          parseInt(packageData.id_packages) ===
          parseInt(editedData.id_packages),
      );
      prevpackagesData[editedIndex] = editedData;
      state.packagesData = prevpackagesData;
      state.packagesUpdateModalVisible = false;
    },
    [packagesUpdateRequest.rejected.type]: (state, action) => {
      state.apiStatus = false;
      state.isLoading = false;
      state.successMessage = "";
      state.error = action.payload?.data?.error;
    },
  },
});

export const {
  clear,
  setLoading,
  setApiStatus,
  clearMessages,
  setCurrentReducer,
  setFilterReducer,
  setStatusReducer,
  setDeleteItemsReducer,
  deleteListCreateReducer,
  setFilterShadow,
  clearDeleteItems,
  setAddModalVisibleReducer,
  setPackagesUpdateModalVisibleReducer,
  clearDataReducer,
} = packagesSlice.actions;

export const packagesState = (state: RootState) => state.packages;

export default packagesSlice.reducer;
